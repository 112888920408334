<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t('eod.eodDate')
                }}</label>
                <input
                    v-model="model.eod_date"
                    type="text"
                    class="form-control"
                    readonly
                />
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t('eod.comment')
                }}</label>
                <textarea
                    v-model="model.rollback_comment"
                    class="form-control"
                    rows="3"
                    :class="{
                        'is-invalid': errors.has('rollback_comment')
                    }"
                ></textarea>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('rollback_comment')"
                >
                    {{ errors.first('rollback_comment') }}
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t('cancel') }}</ts-button
                >
                <ts-button
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting_new"
                    @click.prevent="onSave"
                >
                    {{ $t('save') }}</ts-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'eodForm',
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                company_id: null,
                eod_date: null,
                rollback_comment: null
            }
        }
    },
    computed: {
        ...mapState('creditOperation/eod', ['edit_data'])
    },
    methods: {
        onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('creditOperation/eod/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.company_id = this.edit_data.company_id
                this.model.eod_date = this.edit_data.eod_date
            }
        },
        clearInput () {
            this.model.eod_date = null
            this.model.rollback_comment = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'EOD',
                desc: not.text
            })
        }
    }
}
</script>
