<template>
    <div>
        <ts-page-title
            :title="$t('eod.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('eod.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <Tabs @on-click="changeTab">
                <TabPane label="EoD Process" icon="ios-analytics-outline">
                    <eod-process ref="eod_process"></eod-process>
                </TabPane>
                <TabPane label="Rollback History" icon="ios-timer-outline">
                    <rollback-history ref="rollback_history"></rollback-history>
                </TabPane>
            </Tabs>
        </ts-panel>
    </div>
</template>

<script>
import EodProcess from './eod-process'
import RollbackHistory from './rollback-history'

export default {
    name: 'eod',
    components: {
        EodProcess,
        RollbackHistory
    },
    data () {
        return {}
    },
    methods: {
        changeTab (e) {
            if (e == 0) {
                this.$refs.eod_process.fetchData()
            } else {
                this.$refs.rollback_history.fetchData()
            }
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('creditOperation/eod/RESET_STATE')
        next()
    }
}
</script>
