<template>
    <div>
        <Table
            :columns="columns"
            :data="resources"
            size="small"
            :loading="loading"
            max-height="700"
            stripe
        >
            <template slot-scope="{ row }" slot="company">
                {{ row.company ? row.company.company_name_en : '' }}
            </template>
            <template slot-scope="{ row }" slot="created_by">
                {{ row.created_by.employee ? row.created_by.employee.employee_name_en : row.created_by.user_name }}
            </template>
        </Table>
        <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
            <ts-i-pagination
                v-model="pagination"
                @navigate="fetchData"
            ></ts-i-pagination>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'rollbackHistory',
    data () {
        return {
            loading: false
        }
    },
    created () {
        this.fetchData()
    },
    computed: {
        ...mapState('creditOperation/eodRollbackHistory', [
            'resources',
            'pagination'
        ]),
        columns () {
            return [
                {
                    title: this.$t('eod.company'),
                    slot: 'company',
                    sortable: false
                },
                {
                    title: this.$t('eod.eodDate'),
                    key: 'eod_date',
                    sortable: false
                },
                {
                    title: this.$t('eod.comment'),
                    key: 'rollback_comment',
                    sortable: false
                },
                {
                    title: this.$t('eod.createdBy'),
                    slot: 'created_by',
                    sortable: false
                },
                {
                    title: this.$t('eod.createdDate'),
                    key: 'created_date',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('creditOperation/eodRollbackHistory/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'EOD',
                desc: not.text
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('creditOperation/eodRollbackHistory/RESET_STATE')
        next()
    }
}
</script>
